
import * as React from "react"


class Error extends React.Component {

    render() {
        return (
            <div>
                <body>
                    <section>
                        <p>Your order did not complete! <a href='/' className='underline'>Home</a></p>
                    </section>
                </body>
            </div>
        )
    }
}
export default Error



