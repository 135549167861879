import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import chocobomb from '../images/testpics/chocolate_bomb.jpg'
import raffaello from '../images/testpics/Rafeaello.jpg'
import dark_chocolate_cookies from '../images/testpics/dark_chocolate_cookies.jpg'
import apple_walnut_buntcake from '../images/testpics/apple_walnut_buntcake.jpg'
import tulumbe from '../images/testpics/tulumbe.jpg'
import wine_roll from '../images/testpics/wine_roll.jpg'

class HomeCarousel extends Component {
    render() {
        return (
            <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
                <div>
                    <img src={raffaello} alt="raffaello"/>
                </div>
                <div>
                    <img src={chocobomb} alt="chocobomb"/>
                </div>
                <div>
                    <img src={apple_walnut_buntcake} alt="apple_walnut_buntcake"/>
                </div>
                <div>
                    <img src={dark_chocolate_cookies} alt="dark_chocolate_cookies"/>
                </div>
                <div>
                    <img src={tulumbe} alt="tulumbe"/>
                </div>
                <div>
                    <img src={wine_roll} alt="wine_roll"/>
                </div>
            </Carousel>
        );
    }
};

export default HomeCarousel

