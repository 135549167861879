
import * as React from "react"


class Success extends React.Component {

    render() {
        return (
            <div>
                <body>
                    <section>
                        <p>
                            We appreciate your business! If you have any questions, please email
      <a href="mailto:orders@example.com">orders@example.com</a>.
    </p>
                    </section>
                </body>
            </div>
        )
    }
}
export default Success



