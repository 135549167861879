
import * as React from "react"


class Cancel extends React.Component {

    render() {
        return (
            <div>
                <body>
                    <section>
                        <p>Forgot to add something to your cart? Shop around then come back to pay!</p>
                    </section>
                </body>
            </div>
        )
    }
}
export default Cancel



