import * as React from "react"
import Counter from '../components/counter'

class CheckoutItem extends React.Component {
    constructor(props) {
        // lift state up so Order.js knows total of each component
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = { itemTotal: 0 };
    }

    // set local state then lift up to order.js
    handleChange(itemTotal) {
        this.setState({ itemTotal }, () => {
            this.props.handleChange(itemTotal, this.props.itemid);
        });
    }

    render() {
        return <div className="flex flex-col m-1">

            <div>
                <img src={this.props.url} alt={this.props.caption} />
                <label className="text-sm font-light italic text-gray-700">{this.props.name}</label> <br />
                <label className="text-sm font-light italic text-gray-700">${this.props.priceText}CAD / unit</label>
            </div>

            <div className="flex space-x-1">
                <Counter handleChange={this.handleChange} itemQuantity={this.props.itemQuantity} />
                <div>
                </div>
            </div>

        </div>
    }
}

export default CheckoutItem
