import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ReactGA from 'react-ga';

import '../styles/App.css';
import Gallery from '../pages/Gallery'
import Order from '../pages/Order'
import Home from './Home'

import Success from '../pages/Success'
import Cancel from '../pages/Cancel'
import NotFound from '../pages/NotFound'
import Error from '../pages/Error'


import Headerlogo from "../images/HeaderLogo"
import Mail from "../images/mail.svg"
import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"
import Github from "../images/github.svg"

import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";



const trackingId = "UA-188007723-1";

function initializeReactGA() {
  ReactGA.initialize({trackingId});
  ReactGA.pageview('/');
}
function App() {
  return (
    <main className="min-h-screen">
      <div className="max-w-full min-h-screen sm:mx-6 mx-4 bg-white rounded-3xl items-center">
        {/*TODO: Componentize and make it IKPink */}
        <div className="min-h-screen py-1 px-1 rounded-3xl">
            <div className="min-h-screen py-1 px-1 rounded-3xl">
              <div className="justify-around md:justify-center py-4 px-4 ">
                <Router>
                  <div className="flex justify-center">
                    <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900"><Headerlogo /></Link>
                  </div>
                  <nav className="flex space-x-3 sm:space-x-5 justify-center">
                      <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">HOME</Link>
                    <Link to="/gallery" className="text-base font-medium text-gray-500 hover:text-gray-900">GALLERY</Link>
                    <Link to="/order" className="text-base font-medium text-gray-500 hover:text-gray-900">ORDER</Link>
                  </nav>
                  <hr className="border-gray-200 mt-1 mb-1"></hr>
                  <div className='flex flex-row-reverse h-3 space-x-3 space-x-reverse mx-1'>
                    <a href="mailto:irmaskitchen1@gmail.com" className="h-4">
                    <img src={Mail} alt="mail" className="h-4"/>
                    </a>
                    <a href="https://www.instagram.com/irmas__kitchen">
                    <img src={Instagram} alt="instagram" className="h-4"/>
                    </a>
                    <a href="https://www.facebook.com/IrmasKitchenn">
                    <img src={Facebook} alt="facebook" className="h-4"/>
                    </a>
                  </div>
                  <div className='grid md:grid-cols-8 lg:grid-cols-6'>
                    <div></div>
                    <div className="md:col-span-6 lg:col-span-4 mt-4 mb-4 mx-1">
                      <Switch>
                        <Route path="/gallery">
                          <Gallery />
                        </Route>
                        <Route path="/order">
                          <Order />
                        </Route>
                        <Route path="/termsofservice">
                          <TermsOfService />
                        </Route>
                        <Route path="/privacypolicy">
                          <PrivacyPolicy />
                        </Route>
                        <Route path="/success">
                          <Success />
                        </Route>
                        <Route path="/cancel">
                          <Cancel />
                        </Route>
                        <Route exact path="/Error">
                          <Error />
                        </Route>
                        <Route exact path="/">
                          <Home />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex space-x-3 sm:space-x-5 justify-center">
                      <Link to="/privacypolicy" className="text-xs font-medium text-gray-500 hover:text-gray-900">Privacy Policy</Link>
                    <Link to="/termsofservice" className="text-xs font-medium text-gray-500 hover:text-gray-900">Terms and Conditions</Link>
                  </div>
                  <div className="flex justify-center my-1">
                  <a href="https://github.com/mcle0463" className="flex text-xs font-medium text-gray-500 hover:text-gray-900">
                    Contact the Developer  
                    <img src={Github} alt="github" className="h-4 mx-1"/>               
                    </a>
                  </div>
                </Router>
              </div>
            </div>
          </div>
        </div>
      {initializeReactGA()}
    </main>
  );
}

export default App;
