import * as React from "react"

class Photo extends React.Component {
    render() {
        return <div className="px-1 py-1 md:px-2 md:py-2 max-w-xs md:max-w-md transition duration-350 ease-in-out transform hover:-translate-y-1 hover:scale-103 shadow-lg motion-reduce:transition-none motion-reduce:transform-none">
            <img src={this.props.url} alt={this.props.caption} >
            </img>
        </div>
    }
}

export default Photo
