import * as React from "react"
//import bagels from '../images/testpics/bagels.jpg'
import chocolate_bomb from '../images/testpics/chocolate_bomb.jpg'
import apple_walnut_buntcake from '../images/testpics/apple_walnut_buntcake.jpg'
import bajadera from '../images/testpics/bajadera.jpg'
import blueberry_buntcake from '../images/testpics/blueberry_buntcake.jpg'
import chai_sugar_cookies from '../images/testpics/chai_sugar_cookies.jpg'
import chocolate_almond_balls from '../images/testpics/chocolate_almond_balls.jpg'
import chocolate_bark from '../images/testpics/chocolate_bark.jpg'
import chocolate_chip_cookies from '../images/testpics/chocolate_chip_cookies.jpg'
import coconut_roll from '../images/testpics/coconut_roll.jpg'
import coffee_bean_cookies from '../images/testpics/coffee_bean_cookies.jpg'
import wine_roll from '../images/testpics/wine_roll.jpg'
import citrus_buntcake from '../images/testpics/citrus_buntcake.jpg'
import tulumbe from '../images/testpics/tulumbe.jpg'
import sweet_roll from '../images/testpics/sweet_roll.jpg'
import salty_rolls from '../images/testpics/salty_rolls.jpg'
import raffaello from '../images/testpics/Rafeaello.jpg'
import ginger_molasses_cookies from '../images/testpics/ginger_molasses_cookies.jpg'
import espresso_cookies from '../images/testpics/espresso_cookies.jpg'

import CheckoutItem from '../components/checkoutitem'
import CheckoutButton from '../components/checkoutbutton'
import Disclaimer from '../components/disclaimer'

//NEED MODEL CLASSES
const itemPrices = {
  chocobombx4:30, 
  tulumbex10:30,
  chocolate_chip_cookies:30,
  espresso_cookies:30,
  chocolate_bark:30, //toblorone
  coconut_rollx12:30,
  ginger_molasses_cookies:30,
  wine_rollx12:30,
  sweet_roll:30,
  dark_chocolate_cookies:30,
  bajadera: 30,
  coffee_bean_cookies:30,
  salty_rolls:30,
  raffaello:30,
  chai_sugar_cookies:30,
  chocolate_almond_balls:30,
  gluten_free_sugar_cookies: 30,
  gluten_free_browniesx12:30,
  thumbprint_cookiesx12: 30,
  apple_walnut_buntcake:35,
  blueberry_buntcake:35,
  citrus_buntcake:35
}

class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = {   
      chocobombx4:0, 
      tulumbex10:0,
      chocolate_chip_cookies:0,
      espresso_cookies:0,
      chocolate_bark:0, // toblorone lol
      coconut_rollx12:0,
      ginger_molasses_cookies:0,
      wine_rollx12:0,
      sweet_roll:0,
      dark_chocolate_cookies:0,
      bajadera: 0,
      coffee_bean_cookies:0,
      salty_rolls:0,
      raffaello:0,
      chai_sugar_cookies:0,
      chocolate_almond_balls:0,
      gluten_free_sugar_cookies: 0,
      gluten_free_browniesx12:0,
      thumbprint_cookiesx12: 0,
      apple_walnut_buntcake:0,
      blueberry_buntcake:0,
      citrus_buntcake:0,
      itemTotal:0,
      disclaimerAccepted: true
 };

    this.handleChange = this.handleChange.bind(this);
    this.acceptDisclaimer = this.acceptDisclaimer.bind(this);
  }



  /**
   * On component mount create sessionStorage for Orders or
   * load an existing sessionStorage into state
   */
  componentDidMount() {
    // TODO: Remove or use Session
    sessionStorage.removeItem('itemGrid');
    if (sessionStorage.getItem('itemGrid') === null || sessionStorage.getItem("itemTotal") === null ) {
      sessionStorage.setItem('itemGrid', JSON.stringify(this.state));
    } else {
      var existingItemGrid = JSON.parse(sessionStorage.getItem("itemGrid"))
      this.setState({ 
        chocobombx4: parseInt(existingItemGrid["chocobombx4"]), 
        tulumbex10: parseInt(existingItemGrid["tulumbex10"]), 
        chocolate_chip_cookies: parseInt(existingItemGrid["chocolate_chip_cookies"]), 
        espresso_cookies: parseInt(existingItemGrid["espresso_cookies"]), 
        chocolate_bark: parseInt(existingItemGrid["chocolate_bark"]), 
        coconut_rollx12: parseInt(existingItemGrid["coconut_rollx12"]),
        ginger_molasses_cookies:parseInt(existingItemGrid["ginger_molasses_cookies"]),
        wine_rollx12: parseInt(existingItemGrid["wine_rollx12"]), 
        sweet_roll: parseInt(existingItemGrid["sweet_roll"]), 
        dark_chocolate_cookies: parseInt(existingItemGrid["dark_chocolate_cookies"]), 
        bajadera: parseInt(existingItemGrid["bajadera"]), 
        coffee_bean_cookies: parseInt(existingItemGrid["coffee_bean_cookies"]), 
        salty_rolls: parseInt(existingItemGrid["salty_rolls"]),
        raffaello: parseInt(existingItemGrid["raffaello"]), 
        chai_sugar_cookies: parseInt(existingItemGrid["chai_sugar_cookies"]), 
        chocolate_almond_balls: parseInt(existingItemGrid["chocolate_almond_balls"]), 
        thumbprint_cookiesx12: parseInt(existingItemGrid["thumbprint_cookiesx12"]), 
        apple_walnut_buntcake: parseInt(existingItemGrid["apple_walnut_buntcake"]), 
        blueberry_buntcake: parseInt(existingItemGrid["blueberry_buntcake"]) , 
        citrus_buntcake: parseInt(existingItemGrid["citrus_buntcake"]),
        itemTotal:parseInt(sessionStorage['itemTotal']),
      disclaimerAccepted:sessionStorage['disclaimerAccepted']})
    }

    if (sessionStorage.getItem('disclaimerAccepted') === null  || sessionStorage.getItem('disclaimerAccepted') === 'false') {
      sessionStorage.setItem('disclaimerAccepted', 'false');
      this.setState({disclaimerAccepted : 'false'})
    }
  }

  /**
   * This function called when incrementing or decrementing items
   */
  handleChange(itemTotal, itemId) {
    let oldState = this.state;
    oldState[itemId] = parseInt(itemTotal);
    this.setState((oldState), () => {
      this.updateSessionStorage()
    })
  }
  updateSessionStorage() {
    sessionStorage.setItem('itemGrid', JSON.stringify(this.state))
  }
 acceptDisclaimer() {
  this.setState({disclaimerAccepted : 'true'}, () => {
    sessionStorage.setItem('disclaimerAccepted', 'true');
  })
  this.updateSessionStorage()

 }
    
  render() {
    return (
      // Revolutionary idea - Eventually get prop values from stripe products api so I dont need to spend 20 mins each time we add a new product.
      <div className='grid grid-cols-2 md:grid-cols-4 sm:grid-rows-6'>
        <CheckoutItem url={chocolate_bomb} name='Chocolate Bomb' itemid='chocobombx4' priceText={itemPrices['chocobombx4']} handleChange={this.handleChange} itemQuantity={this.state.chocobombx4} />
        <CheckoutItem url={chocolate_bark} name='Chocolate Bark' itemid='chocolate_bark' priceText={itemPrices['chocolate_bark']} handleChange={this.handleChange} itemQuantity={this.state.chocolate_bark} />
        <CheckoutItem url={chocolate_chip_cookies} name='Chocolate Chip Cookies' itemid='chocolate_chip_cookies' priceText={itemPrices['chocolate_chip_cookies']} handleChange={this.handleChange} itemQuantity={this.state.chocolate_chip_cookies} />
        <CheckoutItem url={espresso_cookies} name='Espresso Cookies' itemid='espresso_cookies' priceText={itemPrices['espresso_cookies']} handleChange={this.handleChange} itemQuantity={this.state.espresso_cookies} />
        <CheckoutItem url={coconut_roll} name='Coconut Roll' itemid='coconut_rollx12' priceText={itemPrices['coconut_rollx12']} handleChange={this.handleChange} itemQuantity={this.state.coconut_rollx12} />
        <CheckoutItem url={ginger_molasses_cookies} name='Ginger Molasses Cookies' itemid='ginger_molasses_cookies' priceText={itemPrices['ginger_molasses_cookies']} handleChange={this.handleChange} itemQuantity={this.state.ginger_molasses_cookies} />
        <CheckoutItem url={wine_roll} name='Wine Roll' itemid='wine_rollx12' priceText={itemPrices['wine_rollx12']} handleChange={this.handleChange} itemQuantity={this.state.wine_rollx12} />
        <CheckoutItem url={sweet_roll} name='Sweet Roll' itemid='sweet_roll' priceText={itemPrices['sweet_roll']} handleChange={this.handleChange} itemQuantity={this.state.sweet_roll} />
        <CheckoutItem url={raffaello} name='Rafaello' itemid='raffaello' priceText={itemPrices['raffaello']} handleChange={this.handleChange} itemQuantity={this.state.raffaello} />
        <CheckoutItem url={chai_sugar_cookies} name='Chai Sugar Cookies' itemid='chai_sugar_cookies' priceText={itemPrices['chai_sugar_cookies']} handleChange={this.handleChange} itemQuantity={this.state.chai_sugar_cookies} />
        <CheckoutItem url={chocolate_almond_balls} name='Chocolate Almond Balls' itemid='chocolate_almond_balls' priceText={itemPrices['chocolate_almond_balls']} handleChange={this.handleChange} itemQuantity={this.state.chocolate_almond_balls} />
        <CheckoutItem url={apple_walnut_buntcake} name='Apple Walnet Bunt Cake' itemid='apple_walnut_buntcake' priceText={itemPrices['apple_walnut_buntcake']} handleChange={this.handleChange} itemQuantity={this.state.apple_walnut_buntcake} />
        <CheckoutItem url={blueberry_buntcake} name='Blueberry Bunt Cake' itemid='blueberry_buntcake' priceText={itemPrices['blueberry_buntcake']} handleChange={this.handleChange} itemQuantity={this.state.blueberry_buntcake} />
        <CheckoutItem url={bajadera} name='Bajadera' itemid='bajadera' priceText={itemPrices['bajadera']} handleChange={this.handleChange} itemQuantity={this.state.bajadera} />
        <CheckoutItem url={coffee_bean_cookies} name='Coffee Bean Cookies' itemid='coffee_bean_cookies' priceText={itemPrices['coffee_bean_cookies']} handleChange={this.handleChange} itemQuantity={this.state.coffee_bean_cookies} />
        <CheckoutItem url={citrus_buntcake} name='Citrus Bunt Cake' itemid='citrus_buntcake' priceText={itemPrices['citrus_buntcake']} handleChange={this.handleChange} itemQuantity={this.state.citrus_buntcake} />
        <CheckoutItem url={salty_rolls} name='Salty Rolls' itemid='salty_rolls' priceText={itemPrices['salty_rolls']} handleChange={this.handleChange} itemQuantity={this.state.salty_rolls} />
        <CheckoutItem url={tulumbe} name='Tulumbe' itemid='tulumbex10' priceText={itemPrices['tulumbex10']} handleChange={this.handleChange} itemQuantity={this.state.tulumbex10} />

      <div className="md:col-span-2"></div>
      <div className= "col-span-2">
        <div className=" text-md font-medium text-gray-700">Est Total: ${(
          this.state.chocobombx4 * itemPrices['chocobombx4'])
         + (this.state.chocolate_bark * itemPrices['chocolate_bark']) 
         + (this.state.tulumbex10 * itemPrices['tulumbex10']) 
         + (this.state.chocolate_chip_cookies * itemPrices['chocolate_chip_cookies']) 
         + (this.state.espresso_cookies * itemPrices['espresso_cookies']) 
         + (this.state.ginger_molasses_cookies * itemPrices['ginger_molasses_cookies']) 
         + (this.state.wine_rollx12 * itemPrices['wine_rollx12']) 
         + (this.state.sweet_roll * itemPrices['sweet_roll']) 
         + (this.state.raffaello * itemPrices['raffaello']) 
         + (this.state.chai_sugar_cookies * itemPrices['chai_sugar_cookies']) 
         + (this.state.chocolate_almond_balls * itemPrices['chocolate_almond_balls']) 
         + (this.state.apple_walnut_buntcake * itemPrices['apple_walnut_buntcake']) 
         + (this.state.blueberry_buntcake * itemPrices['blueberry_buntcake']) 
         + (this.state.bajadera * itemPrices['bajadera'])
         + (this.state.coffee_bean_cookies * itemPrices['coffee_bean_cookies']) 
         + (this.state.citrus_buntcake * itemPrices['citrus_buntcake']) 
         + (this.state.salty_rolls * itemPrices['salty_rolls'])
         + (this.state.coconut_rollx12 * itemPrices['coconut_rollx12'])}
         </div>
          
          {/**TODO: pass with state value */}
        <CheckoutButton itemTotal={(
          this.state.chocobombx4 +
         + this.state.chocolate_bark +
         + this.state.tulumbex10 +
         + this.state.chocolate_chip_cookies +
         + this.state.espresso_cookies +
         + this.state.ginger_molasses_cookies +
         + this.state.wine_rollx12 +
         + this.state.sweet_roll +
         + this.state.raffaello +
         + this.state.chai_sugar_cookies +
         + this.state.chocolate_almond_balls +
         + this.state.apple_walnut_buntcake +
         + this.state.blueberry_buntcake +
         + this.state.bajadera +
         + this.state.coffee_bean_cookies +
         + this.state.citrus_buntcake +
         + this.state.salty_rolls +
         + this.state.coconut_rollx12 )} />
        </div>
        {this.state.disclaimerAccepted === 'false' ? <Disclaimer handleChange={this.acceptDisclaimer}/> : <div/>}      
      </div>
    )
  }
}

export default Order

