
import * as React from "react"


class NotFound extends React.Component {

    render() {
        return (
            <div>
                <body>
                    <section>
                        <p>Oops! Couldn't find what you are looking for. <a href='/' className='underline'>Home</a></p>
                    </section>
                </body>
            </div>
        )
    }
}
export default NotFound



