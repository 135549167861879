import * as React from "react"
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51IAhVqB4MBHoT8Kg2zuYfoGThd1liWV9csnj5RQqrLcklY2LJfmISYXCEDf8BPEslM21Mzk4ZcP63XUcoYutjZDl008vm7HKYT');

const handleClick = async (event) => {
  // Get Stripe.js instance

  const stripe = await stripePromise;

  var requestBody = JSON.stringify({ itemGrid: sessionStorage.getItem("itemGrid"), recaptcha: event.target.value });
  const response = await fetch('/api/create-checkout-session', {
    method: 'POST',
    body: requestBody,
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  if (!response.ok) {
    window.location = 'Error'
  }

  const session = await response.json();

  // When the customer clicks on the button, redirect them to Checkout.
  const result = await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  if (result.error) {
    window.location = 'Error'
  }
}

function onChange(value) {
  console.log("Captcha value:", value)
}


class CheckoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { token: '' };
  }

  componentDidMount() {
    var grecaptcha = window.grecaptcha;
    grecaptcha.ready(function () {
      // do request for recaptcha token
      // response is promise with passed token
      grecaptcha.execute('6LcAjToaAAAAABUSD-jVBlobobKdpITcD0-8Est9', { action: 'validate_captcha' })
        .then(function (token) {
          // add token value to form
          document.getElementById('order_submit_button').value = token;

        });
    });
  }
  render() {
    return (
      <div className="flex flex-col ">
        <button
          id="order_submit_button"
          type="button"
          onClick={handleClick}
          disabled={(this.props.itemTotal < 3 || this.props.itemTotal > 10) ? true : false}
          className="w-1/3 border border-indigo-500 bg-indigo-500 text-white rounded-md px-4 py-2 transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline disabled:opacity-50"
        >
          Checkout
      </button>

        {(this.props.itemTotal > 10) ? 
        <div className="text-red-700 text-sm font-medium bg-red-100 rounded-lg px-1 mt-1 max-w-sm">
          Please review issues with your order
          <br />
            • Maximum 10 items per order
        </div> : ''}

        {(this.props.itemTotal < 3) ? 
        <div className="text-red-700 text-sm font-medium bg-red-100 rounded-lg px-1 mt-1 max-w-sm">
          Please review issues with your order
          <br />
            • Minimum 3 items per order
          </div> : ''}
      </div>
    )
  }
}

export default CheckoutButton
