import * as React from "react"

// Maybe stop using classes so we dont have to .this everything :/
class Counter extends React.Component {
    constructor(props) {

        // lift state up so Order.js knows total of each component
        super(props);
        // this.decrement = this.decrement.bind(this);
        //this.increment = this.increment.bind(this);
        this.quantityUpdate = this.quantityUpdate.bind(this);
        this.state = { total: 0 } //get value from browser session when element is instantiated
    }

    quantityUpdate(e) {
        if (e.target.value > 3 || e.target.value < 0) {
            //TODO: err msg
            return;
        }

        this.props.handleChange(e.target.value);
    }

    componentDidMount() {
        this.setState({ total: this.props.itemQuantity })
    }

    render() {
        return (
            <div>
                <select name="quantity" id="quantity" className="text-sm  font-medium text-gray-700" onChange={this.quantityUpdate}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>
            </div>
        )
    }
}

export default Counter
