import * as React from "react"

class Video extends React.Component {
    render() {
        return <div className="px-1 py-1 md:px-2 md:py-2 max-w-xs md:max-w-md transition duration-350 ease-in-out transform hover:-translate-y-1 hover:scale-103 hover:shadow-lg motion-reduce:transition-none motion-reduce:transform-none">
            <video width="750" height="500" controls >
                <source src={this.props.url} type="video/mp4" alt={this.props.caption} />
            </video>

        </div>
    }
}

export default Video
