import * as React from "react"
import Photo from '../components/photo'
import Video from '../components/video'

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = { imageSource: [] };
    }

    callAPI() {
        fetch("/api/gallery")
            .then(res => res.text())
            .then(res => this.setState({ imageSource: res }));
    }

    componentDidMount() {
        this.callAPI();
    }


    render() {
        
        //TODO: Ugly code plz fix me

        if (!this.state.imageSource.length)
            return null;

        var parsedJSON = JSON.parse(this.state.imageSource);
        var array = [];

        for (var i = 0; i < parsedJSON.data.length; i++) {
            array.push(parsedJSON.data[i]);
        }

        let media_content = array.map((el, i) => {
            if (el.media_type === "VIDEO") {
                return <Video key={i}  url={el.media_url} />
            } else {
                return <Photo key={i} url={el.media_url} />
            }
        })

        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-center">
            {media_content}
            </div>
        );
    }
}

export default Gallery
