import * as React from "react"
import HomeCarousel from '../components/carousel'

class Home extends React.Component {

    render() {
        return (
            <div className="flex flex-col space-y-5">
                <p>
                Hey there! I’m Irma, welcome to my Kitchen!
<br/>
<br/>
Now that you’re here, you might as well  grab a cookie and take a seat while I introduce myself.
<br/>
<br/>
My love for baking started early but if you ask my mom, she would say my love for making a mess in the kitchen started when I was a little girl! Nonetheless, I was always encouraged to get creative in the kitchen. Now here we are, Irma’s Kitchen. I’m based in Ottawa, Canada and I’m so glad you’re here.
                    
                                  </p>
                    <div className="grid sm:grid-cols-6">
                        <div className="sm:col-span-1"> </div>
                        <div className="sm:col-span-4">
                            <HomeCarousel />
                        </div>
                        <div className="sm:col-span-1"> 
                        </div></div>
            </div>
        )
    }
}
export default Home
